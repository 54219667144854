@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

body {
  overflow: hidden;
  font-size: 16px;
  line-height: 1.5;
}

h1 {
  font-size: 30px;
}

.slider-container,
.filter,
.sebm-google-map-container,
.map-sidebar-button {
  font-size: 14px;
  line-height: 1;
}

.ui-menuitem-text {
  font-size: 17px;
}

// Fix poorly implemented ng-bootstrap classes
.modal.fade.show .modal-dialog {
  transform: translate(0, 0);
}

.modal-backdrop.fade.show {
  opacity: 0.1;
}

p {

}

label, .data-label {
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
}

.color {
  &--white {
    color: white;
  }
}

.theme-color {
  background: #FE880D;
}
.btn.theme-color {
  color: #fff;
}

.btn-blue {
  background-color: #2399E5;
  border: 1px solid #1f89ce;
  color: white;
}
.btn-blue.btn-outline {
  color: #2399E5;
  border: 1px solid #2399E5;
  background: none;
}

.btn-tab {
  border-radius: 0;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom-width: 2px;
  padding: 13px 20px;

  &.selected {
    color: #337AB7;
    border-bottom-color: #337AB7;
  }
}

.capsules {
  .ui-button {
    border-radius: 30px;
    padding: 4px 0;
    margin-right: 4px;
    margin-bottom: 7px;

    .ui-clickable {
      font-weight: normal;
      font-size: 12px;
    }
  }
}

.no-margin {
  margin-top: 0!important;
  margin-bottom: 0!important;
}

.no-padding {
  padding-right: 0;
  padding-left: 0;
}

form {
  .title {
    margin-bottom: 25px;
  }
}

.form-group {
  margin-bottom: 40px;
}

.form-control {
  height: 43px;
}

button[type=submit] {
  width: 200px;
  height: 40px;
  color: white;
  border: 0;
}

.content-container {
  width: 960 / 1600 * 100%;
  min-width: 960px;
  margin: auto;
  padding: 20px 10%;
  box-sizing: border-box;
  background: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 100px);

  &.no-bg {
    background: transparent;
  }

  &.no-padding {
    padding-left: 0;
    padding-right: 0;
  }

  form, .row {
    width: 100%;
  }

  .width--100 {
    width: 100%;
  }
}

.flex {
  display: flex;

  .content {
    width: 100%;
  }


}

.box {
  margin-bottom: 35px;

  .content {
    padding: 6.4%;
    //FF and IE HACK
    padding-top: 40px;
    padding-bottom: 40px;
    background: white;

    &.no-bg {
      background: transparent;
      padding: 0 calc(6.4% + 5px);
    }

    &.bg--grey {
      background: #f9f9f9;
    }
  }

  .vertical-line {
    width: 5px;
  }
}

.date-slider {
  .ui-slider-horizontal {
    height: 36px !important;
    background-color: #3c5d99;
    -webkit-box-shadow: inset 0px 0px 10px 1px rgba(255,255,255,1);
    -moz-box-shadow: inset 0px 0px 10px 1px rgba(255,255,255,1);
    box-shadow: inset 0px 0px 10px 1px rgba(255,255,255,1);
    margin-top: 4px;

    .ui-slider-handle {
      top: -4px !important;
      background: #789de0;
      border-radius: 4px;
      border: 2px solid #fff;
      opacity: 0.8;
      height: 44px !important;
      cursor: pointer;
    }
  }

  .ui-slider-range-min {
    background: none !important;
  }
}
.modal-content {
  background: none;
  box-shadow: none;
  border: none;

  .modal-header,
  .modal-footer {
    border: none;
    padding: 0;
  }

}

// TODO: CSS HAX for bugged PopupComponent (doesn't remove fade class when its opened)
// Should be fixed and removed
.modal-open .modal.fade.show {
  opacity: 1;
}

button.navbar-toggle {
  margin-top: 4px;
  margin-bottom: 4px;
}


@media all and (max-width: 980px) {
  .content-container {
    min-width: 0;
    width: 100%;
  }
}

@media all and (max-width: 768px) {
  .content-container {
    padding-left: 2%;
    padding-right: 2%;
  }
}

.text-center {
  text-align: center;
}

.cursor-pointer {
  cursor: pointer;
}

// Bootstrap adds margin bottom to these which causes vertical positioning problems
.ui-chkbox-label {
  margin-bottom: 0 !important;
}

// NgbTooltip has 0 opacity by default even when shown... I don't understand why
.tooltip.show {
  opacity: 1 !important;
}

// Icons

.icon {
  display: inline-block;
  vertical-align: text-top;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 15px;
  height: 15px;
}

.icon-stats {
  background-image: url('/images/icons/stats.svg');
}
.icon-layer-group {
  background-image: url('/images/icons/layer-group.svg');
}
.icon-pen-nib-blue {
  background-image: url('/images/icons/pen-nib-blue.svg');
}
.icon-moment {
  background-image: url('/images/icons/moment.svg');
}
.icon-range {
  background-image: url('/images/icons/range.svg');
}
.icon-fullscreen {
  background-image: url('/images/icons/fullscreen.svg');
}
.icon-xls-export {
  background-image: url('/images/icons/xls-export.svg');
}
.icon-sync {
  background-image: url('/images/icons/sync.svg');
}
.icon-camera {
  background-image: url('/images/icons/camera.svg');
}

.sidebar-list-wrapper {
  max-height: 200px;
  overflow: auto;
}
